import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="Coupable d’alcool au volant : comment garder son permis de conduire?"
        description="Coupable d’alcool au volant? Consultez un avocat pour garder votre permis de conduire!">
        <p>
            <strong>
                La révocation du permis de conduire est une conséquence directe
                de l’alcool au volant.{' '}
            </strong>
        </p>
        <p>
            Se faire retirer le droit de conduire peut grandement impacter vos
            déplacements quotidiens, et même compromettre votre stabilité
            d’emploi. Alors comment conserver son permis et son droit de
            conduire suite à une infraction d’alcool au volant?{' '}
        </p>
        <p>
            <Img
                fluid={data.img0.childImageSharp.fluid}
                title="Garder son permis alcool au volant"
                alt="Une image contenant voiture"
            />
        </p>
        <p>
            <strong>C’est ce que </strong>
            <Link to="/">
                <strong>Avocatsalcoolauvolant.ca</strong>
            </Link>
            <strong> vous explique en détail juste ici. </strong>
        </p>
        <p>
            Découvrez comment conserver votre permis malgré votre condamnation
            pour conduite avec facultés affaiblies, et remplissez notre
            formulaire pour entrer en contact gratuitement avec un avocat
            criminaliste spécialisé dans le domaine.
        </p>
        <p>
            <strong>
                Nos services de mise en contact sont gratuits et ne vous
                engagent en rien!{' '}
            </strong>
        </p>
        <h2>
            Qu’arrive-t-il avec le permis de conduire et le véhicule en cas
            d’alcool au volant?{' '}
        </h2>
        <p>
            <strong>
                Dès le moment de l’arrestation pour conduite avec facultés
                affaiblies, le permis de conduire du conducteur est suspendu.{' '}
            </strong>
        </p>
        <p>
            En effet, il s’agit d’une suspension administrative de 90 jours qui
            s’applique de façon automatique, et à tous les conducteurs arrêtés
            pour alcool au volant. Même les conducteurs qui en sont à leur
            première infraction seront assujettis à cette suspension, et ce,
            avant même qu’un verdict de culpabilité criminelle ne soit rendu.{' '}
        </p>
        <p>
            <strong>
                Votre véhicule peut également être SAISI lors d’une arrestation
                pour alcool au volant – Voici les situations qui justifieront la
                saisie de l’automobile du conducteur.{' '}
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>Première infraction </p>
                    <p>(Alcoolémie sous 80mg/100ml)</p>
                </td>
                <td>
                    <p>Aucune saisie du véhicule </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Première infraction </p>
                    <p>(Double de la limite d’alcool permise)</p>
                </td>
                <td>
                    <p>Saisie du véhicule pour 30 jours </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Récidive alcool au volant </p>
                </td>
                <td>
                    <p>Saisie du véhicule pour 90 jours</p>
                </td>
            </tr>
        </table>
        <p>
            Le permis est suspendu pendant
            <strong> 90 jours automatiquement</strong>, dès l’arrestation.
            <strong> </strong>Pour une première infraction d’alcool au volant,
            si la personne est reconnue coupable, une{' '}
            <strong>interdiction de conduire de 1 an</strong> lui sera également
            appliquée en vertu du Code criminel et du Code de la sécurité
            routière.
        </p>
        <p>
            <strong>
                Et c’est pendant cette période d’interdiction qu’il sera
                possible de demander un permis restreint!{' '}
            </strong>
        </p>
        <h2>
            Suspension de permis pour 90 jours: une conséquence incontournable!{' '}
        </h2>
        <p>
            <strong>
                Pendant la période de suspension de permis de 90 jours, il n’est
                pas possible pour le conducteur d’obtenir un permis restreint.{' '}
            </strong>
        </p>
        <p>
            <Img
                fluid={data.img1.childImageSharp.fluid}
                title="Suspension de permis 90 jours"
                alt="Une image contenant voiture, personne, homme, véhicule"
            />
        </p>
        <p>
            La suspension administrative de 90 jours est considérée comme une
            interdiction absolue de conduire. Le fait d’avoir besoin de son
            véhicule pour son emploi ou pour tout autre motif important ne
            change rien à la donne. Le conducteur est tenu de respecter la
            suspension de son permis pendant 90 jours.{' '}
        </p>
        <p>
            <strong>
                La seule possibilité de conserver son droit de conduire pendant
                cette période implique de s’adresser au Tribunal administratif.{' '}
            </strong>
        </p>
        <p>
            Cette démarche implique d’engager un avocat afin de contester la
            suspension du permis imposée par la SAAQ devant le tribunal. Le taux
            de succès d’une telle démarche est cependant très limité, et c’est
            pourquoi peu de conducteurs tentent cette approche.{' '}
        </p>
        <h2>Comment lever la suspension de permis après 90 jours? </h2>
        <p>
            <strong>
                Pour une 1<sup>ère</sup> infraction d’alcool au volant, le
                permis redevient valide automatiquement après 90 jours!{' '}
            </strong>
        </p>
        <p>
            La levée automatique de la suspension s’appliquera seulement si le
            taux d’alcoolémie du conducteur se trouvait en dessous du double de
            la limite permise d’alcool dans le sang (moins de 160mg/100ml de
            sang). Un conducteur dont le taux d’alcoolémie dépasse le double de
            la limite devra se soumettre au programme d’évaluation et de
            réduction des risques de la SAAQ.
        </p>
        <p>
            Et pour ce qui est d’une <strong>récidive</strong>, et si le taux
            d’alcoolémie dépasse le double de la limite permise, il faut suivre
            quelques étapes avant de conduire à nouveau après le 90 jours de
            suspension.{' '}
        </p>
        <p>
            <strong>
                Voici les étapes à respecter pour lever la suspension de 90
                jours pour les conducteurs qui n’ont pas droit à la levée
                automatique.
            </strong>{' '}
        </p>
        <ol>
            <li>
                <strong>
                    Lettre de la SAAQ vous convoquant au Programme d’évaluation
                    du risque
                </strong>
            </li>
            <li>
                <strong>Évaluation partielle du risque par la SAAQ </strong>
            </li>
            <li>
                <strong>
                    Décision de la SAAQ (Maintien ou levée de la suspension){' '}
                </strong>
            </li>
            <li>
                <strong>Évaluation complète de la SAAQ (Si nécessaire) </strong>
            </li>
            <li>
                <strong>Nouvelle décision de la SAAQ</strong>{' '}
                <strong>(Maintien ou levée de la suspension) </strong>
            </li>
        </ol>
        <p>
            À chacune de ces étapes, un avocat spécialisé en matière d’alcool au
            volant peut vous venir en aide pour faire lever la suspension de
            votre permis.{' '}
        </p>
        <p>
            <strong>
                Avocatsalcoolauvolant.ca est LA référence pour trouver un avocat
                compétent en la matière!{' '}
            </strong>
        </p>
        <h2>
            Condamnation criminelle pour alcool au volant et révocation du
            permis{' '}
        </h2>
        <p>
            Le fait d’obtenir un permis restreint signifie que votre permis a
            été <strong>révoqué</strong> suite à un verdict de culpabilité rendu
            à votre endroit. Avant ce verdict, votre permis avait seulement été
            suspendu par la SAAQ, que ce soit pendant 90 jours ou pour une
            période plus longue, selon le cas.{' '}
        </p>
        <p>
            <Img
                fluid={data.img2.childImageSharp.fluid}
                title="Condamnation criminelle alcool au volant"
                alt="Une image contenant personne, homme"
            />
        </p>
        <p>
            Le fait d’être reconnu coupable au criminel a pour effet de révoquer
            votre permis, et d’imposer une interdiction de conduire. Cette
            interdiction de conduire est de 1 an pour une première infraction
            reliée à l’alcool au volant, et c’est pendant cette période
            d’interdiction qu’il est possible de demander un permis restreint.{' '}
        </p>
        <p>
            <strong>
                L’obtention d’un permis restreint est donc le meilleur moyen de
                conserver votre droit de conduire après une infraction d’alcool
                au volant.{' '}
            </strong>
        </p>
        <h2>
            Qu’est-ce qu’un permis de conduire restreint pour alcool au volant
            au Québec?{' '}
        </h2>
        <p>
            Un verdict de culpabilité pour un infraction d’alcool au volant a
            pour effet de révoquer le permis de conduire, et donc le droit de
            conduire… Pour continuer à conduire malgré ce verdict, il est
            nécessaire de demander un permis restreint à la SAAQ.{' '}
        </p>
        <p> </p>
        <h3>
            À qui s’adresse le permis de conduire restreint et comment
            l’obtenir?{' '}
        </h3>
        <p>
            Le permis restreint permet aux individus coupables d’une infraction
            reliée à l’alcool au volant de conduire les véhicules munis d’un
            antidémarreur éthylométrique. Les conducteurs dont le permis est
            déjà visé par une sanction et les apprentis-conducteurs ne sont
            toutefois pas éligibles au permis restreint.{' '}
        </p>
        <p>
            Lorsqu’un tel permis est accordé en matière d’alcool au volant, la
            pose d’un antidémarreur éthylométrique est une condition obligatoire
            imposée par la SAAQ. La pose de l’appareil se fait d’ailleurs
            entièrement aux frais du conducteur.{' '}
        </p>
        <h3>Quelles sont les étapes pour obtenir un permis restreint? </h3>
        <p>
            Voici les grandes étapes à respecter pour obtenir un permis
            restreint lors d’une situation d’alcool au volant!<strong> </strong>
        </p>
        <ol>
            <li>
                <strong>
                    Attendre la fin de l’interdiction absolue de conduire{' '}
                </strong>
            </li>
            <li>
                <strong>
                    Signer une entente de service pour un antidémarreur
                    éthylométrique
                </strong>
            </li>
            <li>
                <strong>
                    Présenter le contrat à la SAAQ et payer les frais{' '}
                </strong>
            </li>
            <li>
                <strong>
                    Faire installer l’antidémarreur éthylométrique par Smart
                    Start
                </strong>
            </li>
            <li>
                <strong>
                    Respecter toutes les conditions assorties au permis
                    restreint{' '}
                </strong>
            </li>
        </ol>
        <p>
            <strong>
                Le permis restreint n’est pas un privilège accordé à tous, et la
                SAAQ peut déterminer qu’un conducteur n’y est pas admissible.{' '}
            </strong>
        </p>
        <p>
            Les servies d’un avocat peuvent donc être retenus pour contester une
            telle décision afin d’obtenir un permis de conduire restreint malgré
            votre condamnation pour alcool au volant.{' '}
        </p>
        <h2>
            Programme d’évaluation du risque et Alcofrein : ce que vous devez
            savoir!
        </h2>
        <p>
            <strong>
                Une personne qui se fait arrêter pour alcool au volant peut être
                contraint de passer une évaluation du risque par la SAAQ.
            </strong>
        </p>
        <p>
            Le Programme d’évaluation et de réduction du risque de conduite avec
            les facultés affaiblies (PERRCA) vise à évaluer les habitudes de
            consommation et de conduite du contrevenant.{' '}
        </p>
        <p>
            <Img
                fluid={data.img3.childImageSharp.fluid}
                title="Programme Alcofrein de la SAAQ"
                alt="Une image contenant intérieur, personne, mur, pièce"
            />
        </p>
        <p>
            Une évaluation sommaire peut être exigée, laquelle ne dure que
            quelques heures. Toutefois, un conducteur qui échoue cette
            évaluation sommaire devra se soumettre à une évaluation complète qui
            peut durer entre 7 et 9 mois.{' '}
        </p>
        <p>
            <strong>
                Le conducteur peut également être contraint de suivre le
                programme AlcoFrein avant de récupérer son permis de conduire.{' '}
            </strong>
        </p>
        <p>
            Il s’agit d’une séance de 3 heures lors de laquelle le conducteur
            est instruit sur les conséquences de l’alcool au volant, sur les
            mythes reliés à cette infraction, et sur les bonnes habitudes à
            prendre en matière de consommation d’alcool et de conduite.
            <strong> </strong>
        </p>
        <p>
            Suivre ces programmes peut être une condition imposée par la SAAQ
            pour autoriser l’octroi d’un permis restreint ou lever la suspension
            d’un permis. Le taux d’échec au programme PERRCA est cependant très
            élevé, et il peut être judicieux de consulter un avocat avant de s’y
            soumettre.{' '}
        </p>
        <h2>
            Devrez-vous installer un antidémarreur éthylométrique? Les frais à
            prévoir!{' '}
        </h2>
        <p>
            La pose d’un antidémarreur éthylométrique fait partie intégrante de
            l’obtention d’un permis restreint. Et au Québec, c’est l’entreprise
            Smart Start qui procède à l’installation de ces appareils de
            détection d’alcool.
        </p>
        <p>
            <strong>
                La pose d’un antidémarreur vient également avec des frais à
                acquitter – en voici un aperçu!{' '}
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>Coût d’installation </p>
                </td>
                <td>
                    <p>50$ + tx</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Frais mensuel </p>
                </td>
                <td>
                    <p>61$ + tx</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Coût total pour 1 année </p>
                </td>
                <td>
                    <p>782$ + tx</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Retrait de l’antidémarreur </p>
                </td>
                <td>
                    <p>Sans frais </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Récupérer son permis de conduire</p>
                </td>
                <td>
                    <p>300$ à 400$ </p>
                </td>
            </tr>
        </table>
        <p>
            Un antidémarreur sera généralement imposé au conducteur pour une
            période de 1 an. Cela implique que pendant toute une année, le
            conducteur ne pourra conduire aucun autre véhicule que ceux munis
            d’un antidémarreur à détection d’alcool.{' '}
        </p>
        <p>
            <strong>
                Il faut également s’attendre à ce que les coûts d’assurance
                automobile soient plus élevés suite à une condamnation pour
                alcool au volant!{' '}
            </strong>
        </p>
        <h2>
            L’obtention d’un permis restreint vient avec des conditions à
            respecter!{' '}
        </h2>
        <p>
            Obtenir un permis restreint vous permet de conserver le droit de
            conduire, mais pas à n’importe quelles conditions. Le fait de
            bafouer les limites imposées par le permis restreint et
            l’antidémarreur éthylométrique peut s’avérer lourd de conséquences.{' '}
        </p>
        <p>
            <Img
                fluid={data.img4.childImageSharp.fluid}
                title="Conditions pour obtenir un permis restreint"
                alt="Une image contenant personne, homme"
            />
        </p>
        <p>
            <strong>
                Voici les critères et conditions à respecter avec un permis
                restreint en main!
            </strong>{' '}
        </p>
        <ul>
            <li>
                <strong>
                    <em>Ne pas consommer d’alcool avant de conduire </em>
                </strong>
            </li>
            <li>
                <strong>
                    <em>
                        Respecter les entretiens périodiques de l’antidémarreur{' '}
                    </em>
                </strong>
            </li>
            <li>
                <strong>
                    <em>Ne pas tenter de tromper l’antidémarreur </em>
                </strong>
            </li>
        </ul>
        <p>
            <strong>
                Vous tentez de contourner l’antidémarreur éthylométrique?
                ATTENTION aux conséquences!{' '}
            </strong>
        </p>
        <p>
            Tenter de déjouer un antidémarreur en faisant souffler quelqu’un
            d’autre, par exemple, peut mener à de lourdes amendes, et même à un
            des accusations criminelles pour avoir conduit lors d’une période
            d’interdiction.{' '}
        </p>
        <p>
            Vous aimeriez recevoir les conseils d’un avocat pour conserver votre
            droit de conduire malgré votre condamnation?{' '}
        </p>
        <p>
            <strong>
                Contactez Avocatsalcoolauvolant.ca pour être référé aux
                meilleurs professionnels en matière de conduite avec les
                facultés affaiblies!{' '}
            </strong>
        </p>
        <h2>Consultez un avocat pour conserver votre droit de conduire! </h2>
        <p>
            <strong>
                Ne laissez pas une erreur de parcours vous priver du droit de
                conduire.{' '}
            </strong>
        </p>
        <p>
            Consultez un avocat rapidement pour conserver votre permis ou pour
            obtenir un permis de conduire restreint. Nous sommes justement en
            mesure de vous référer aux avocats spécialisés en matière d’alcool
            au volant n’importe où au Québec.{' '}
        </p>
        <p>
            <strong>
                Remplissez notre formulaire en quelques clics et on assure une
                mise en contact rapide et gratuite avec des avocats
                d’expérience.{' '}
            </strong>
        </p>
    </SeoPage>
)

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "garder-permis-alcool-volant/alcool-volant-garder-permis.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "garder-permis-alcool-volant/suspension-90-jours-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "garder-permis-alcool-volant/alcool-volant-permis-conduire.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "garder-permis-alcool-volant/programme-evaluation-alcofrein-saaq.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "garder-permis-alcool-volant/permis-conduire-restreint-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
